import React from 'react';
import { LineItem } from '@commercetools/frontend-domain-types/wishlist';
import { EditionModeProps } from 'components/commercetools-ui/organisms/nikon-care/components/edition-mode/EditionMode.type';
import { PreviewModeProps } from '../components/preview-mode/PreviewMode.type';
import { useNikonCareStateManagement } from '../context/NikonCareStateManagement';

export const useNikonCarePreviewMode = ({
  warrantyPlans,
  quantity,
  warrantyPlanVariant,
}: Pick<EditionModeProps, 'warrantyPlans' | 'quantity'> & {
  // Injected warrantyPlanChosen, meaning it's not state based the plan selected.
  warrantyPlanVariant?: LineItem['variant'];
}): PreviewModeProps => {
  const {
    dropdown: { selectedPlanCategory, selectedPlan },
  } = useNikonCareStateManagement();

  const planCategorySelected = React.useMemo(() => {
    if (!warrantyPlanVariant) return warrantyPlans?.find((plan) => plan.value === selectedPlanCategory);
    return warrantyPlans?.find((plan) => plan.options.find((option) => option.value === warrantyPlanVariant?.sku));
  }, [warrantyPlans, selectedPlanCategory, warrantyPlanVariant]);

  const planSelected = React.useMemo(() => {
    if (!warrantyPlanVariant) return planCategorySelected?.options?.find((plan) => plan.value === selectedPlan);
    return planCategorySelected?.options?.find((plan) => plan.value === warrantyPlanVariant?.sku);
  }, [warrantyPlans, selectedPlan, planCategorySelected, warrantyPlanVariant]);

  return {
    planCategorySelected: planCategorySelected || { value: '', label: '', options: [] },
    planSelected: planSelected || { value: '', label: '', price: '', priceSubtotal: '' },
    quantity,
  };
};
