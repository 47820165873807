import { useCallback } from 'react';
import useSWR from 'swr';
import { sdk } from 'sdk';
import { Category } from 'types/category';
import { Inventory } from 'types/inventory';
import { Product } from 'types/product';
import { revalidateOptions } from 'frontastic';
import { UseProductReturn } from './types';
import { ENikonProductTypeKeys, TNikonProduct } from '../../../types/TNikonProduct';

const useProduct = (): UseProductReturn => {
  const extensions = sdk.composableCommerce;

  const categoriesResults = useSWR(
    '/action/product/queryCategories',
    () => extensions.product.queryCategories({ limit: 99 }),
    revalidateOptions,
  );

  const categories = (categoriesResults.data?.isError ? [] : (categoriesResults.data?.data?.items as Category[])) ?? [];

  const query: UseProductReturn['query'] = useCallback(async (productQuery) => {
    /* To Do: Use SDK instead of current workaround */

    const extensions = sdk.composableCommerce;
    const res = await extensions.product.query({ ...productQuery });

    return res;

    // const params = new URLSearchParams();

    // if (productQuery.query) params.set('query', productQuery.query);
    // if (productQuery.limit) params.set('limit', productQuery.limit);

    // const res = await fetchApiHub(`/action/product/query?${params.toString()}`, SDK.locale);

    // return { data: res, isError: false } as SDKResponse<Result>;
  }, []);

  const getInventory = useCallback(async (sku: string) => {
    const response = await sdk.callAction({ actionName: 'product/getInventory', query: { sku } });
    return (response.isError ? {} : response.data) as Inventory;
  }, []);

  const getProductByKey = useCallback(async (key: string) => {
    const response = await sdk.callAction({ actionName: 'product/getProductByKey', query: { key } });
    return (response.isError ? {} : response.data) as Product;
  }, []);

  const getProductBySku = useCallback(async (sku: string) => {
    const response = await sdk.callAction({ actionName: 'product/getProductBySku', query: { sku } });
    return (response.isError ? {} : response.data) as TNikonProduct;
  }, []);

  const getProductsBySkus = async (skus: string[]): Promise<TNikonProduct[]> => {
    if (skus.length === 0) {
      return [];
    }

    return (await Promise.all(skus.map((sku) => getProductBySku(sku)))) as TNikonProduct[];
  };

  const getWarrantyPlans = useCallback(async (sku: string) => {
    const response = await sdk.callAction({
      actionName: 'product/getWarrantyPlans',
      query: {
        sku: sku,
        warrantyProductTypeKey: ENikonProductTypeKeys.WARRANTY_PLANS,
      },
    });

    return (response.isError ? [] : response.data) as TNikonProduct[];
  }, []);

  return { categories, query, getInventory, getProductByKey, getProductBySku, getProductsBySkus, getWarrantyPlans };
};

export default useProduct;
