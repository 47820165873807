import React from 'react';
import { Cart, LineItem } from 'types/cart';
import { Order } from 'types/order';
import { useCart } from 'frontastic';
import { NikonCareStateManagementContextProps } from './NikonCareStateManagement.type';
import { NikonCareMode, NikonCareProps } from '../../NikonCare.type';

const NikonCareStateManagementContext = React.createContext<NikonCareStateManagementContextProps>({
  warrantyLineItem: undefined,
  isLoading: false,
  mode: 'edition',
  isNikonCareActive: false,
  onNikonCareToggleValue: () => {},
  onAddToCart: async () => {},
  onCloseNikonCare: () => {},
  switchMode: () => {},
  dropdown: {
    onSelectPlanCategory: () => {},
    selectedPlanCategory: '',
    onSelectPlan: () => {},
    selectedPlan: '',
  },
  productLineItemId: '',
});

export const NikonCareStateManagementProvider = ({
  children,
  quantity,
  lineItem,
  order,
  type = 'cart',
}: React.PropsWithChildren<{ quantity: number; lineItem: LineItem; order?: Order; type?: NikonCareProps['type'] }>) => {
  const [mode, setMode] = React.useState<NikonCareMode>('edition');
  const [isNikonCareActive, setIsNikonCareActive] = React.useState(false);
  const { data: cartData, addItem, removeItem, updateItem } = useCart();
  const [warrantyLineItem, setWarrantyLineItem] = React.useState<LineItem | undefined>();
  const [isLoading, setIsLoading] = React.useState(false);

  const skuAssociated = React.useMemo(() => {
    if (type === 'cart') return lineItem?.custom?.fields?.warrantyPlanSku;

    return (
      cartData?.lineItems.find((item) => item.variant.sku === lineItem.variant.sku)?.custom?.fields?.warrantyPlanSku ||
      ''
    );
  }, [lineItem?.custom?.fields?.warrantyPlanSku, cartData?.lineItems, lineItem.variant.sku, type]);

  // Cart data source from cartData current state or order data provided.
  const cartDataWrapped = React.useMemo(() => order || cartData, [order, cartData]);

  React.useEffect(() => {
    // 0. Reset the state if the context gets an updated sku.
    setIsNikonCareActive(false);
    setSelectedPlan('');
    setSelectedPlanCategory('');
    if (!cartDataWrapped?.lineItems.length) return;

    // 1. Get warranty plan from the cart.
    const warrantyPlan = cartDataWrapped.lineItems.find((item) => item.variant.sku === skuAssociated);
    if (!warrantyPlan) return;

    // 2. Set the state with the warranty plan from the cart.
    setIsNikonCareActive(true);
    if (type === 'pdp') setMode(() => 'freeze');
    if (type !== 'pdp') setMode(() => 'preview');
    setSelectedPlan(warrantyPlan.variant.sku);
    setSelectedPlanCategory(() =>
      warrantyPlan.variant.attributes?.accidentalDamageCoverage ? 'nikon.care.plus' : 'nikon.care',
    );

    // 3. Set the state to avoid adding the warranty plan to the cart.
    setWarrantyLineItem(warrantyPlan);
  }, [cartDataWrapped, type, skuAssociated, lineItem.variant.sku]);

  const [selectedPlanCategory, setSelectedPlanCategory] = React.useState<string | number>('');
  const [selectedPlan, setSelectedPlan] = React.useState('');

  const handleSelectPlanCategory = React.useCallback((value: string | number) => {
    setSelectedPlanCategory(() => value);
    setSelectedPlan(() => '');
  }, []);

  const handleSelectPlan = React.useCallback((value: string) => {
    setSelectedPlan(() => value);
  }, []);

  const handleNikonCareToggleValue = React.useCallback(() => {
    if (!!warrantyLineItem) return;
    setIsNikonCareActive((prev) => {
      const newValue = !prev;

      if (!newValue) {
        setSelectedPlanCategory('');
        setSelectedPlan('');
      }
      return newValue;
    });
  }, [warrantyLineItem]);

  const switchMode = React.useCallback((value: NikonCareMode) => {
    setMode(() => value);
  }, []);

  const handleAddToCart = React.useCallback(
    // cartDataInjected is only provided from PDP page.
    async (cartDataInjected?: Cart) => {
      try {
        if (type === 'pdp' && !cartDataInjected) throw new Error('cartDataInjected is required for PDP page.');
        if (type === 'cart' && !!warrantyLineItem && warrantyLineItem.variant.sku === selectedPlan)
          throw new Error("Can't update the same warranty in the Cart page.");

        // Cart page matches the line item provided otherwise from the product line item.
        const productLineItem =
          type === 'cart'
            ? lineItem
            : cartDataInjected?.lineItems.find((item) => item.variant.sku === lineItem.variant.sku);
        // Cart page matches the quantity provided otherwise from the product line item.
        const productQuantity = type === 'cart' ? quantity : productLineItem?.count || 0;

        if (!productLineItem) return;

        setIsLoading(true);
        if (!warrantyLineItem) {
          const response = await Promise.all([
            addItem({ sku: selectedPlan }, productQuantity),
            updateItem(String(productLineItem.lineItemId), productQuantity, '', { warrantyPlanSku: selectedPlan }),
          ]);
          return response[1];
        }
        if (!!warrantyLineItem && warrantyLineItem?.count === productQuantity) {
          const response = await Promise.all([
            removeItem(String(warrantyLineItem.lineItemId)),
            addItem({ sku: selectedPlan }, productQuantity),
            updateItem(String(productLineItem.lineItemId), productQuantity, '', { warrantyPlanSku: selectedPlan }),
          ]);
          return response[2];
        }

        const conditionalWarrantyDeduction = () => {
          const newQuantity = (warrantyLineItem?.count || 0) - productQuantity;
          return newQuantity > 0
            ? updateItem(String(warrantyLineItem.lineItemId), newQuantity)
            : removeItem(String(warrantyLineItem.lineItemId));
        };
        if (!!warrantyLineItem && warrantyLineItem?.count !== productQuantity) {
          const response = await Promise.all([
            conditionalWarrantyDeduction(),
            addItem({ sku: selectedPlan }, productQuantity),
            updateItem(String(productLineItem.lineItemId), productQuantity, '', { warrantyPlanSku: selectedPlan }),
          ]);
          return response[2];
        }
        setIsLoading(false);
      } catch (error) {
        console.log(':: handleAddToCart -> error', error);
        setIsLoading(false);
      }
    },
    [quantity, selectedPlan, warrantyLineItem, lineItem, type],
  );

  const handleCloseNikonCare = React.useCallback(() => {
    const closingNikonCareSideEffects = () => {
      setIsNikonCareActive(false);
      setMode('edition');
      setSelectedPlanCategory('');
      setSelectedPlan('');
    };

    if (!warrantyLineItem) {
      closingNikonCareSideEffects();
      return;
    }

    if (warrantyLineItem.count === quantity)
      Promise.all([
        removeItem(String(warrantyLineItem.lineItemId)),
        updateItem(String(lineItem.lineItemId), quantity, '', { warrantyPlanSku: '' }),
      ]);
    if (warrantyLineItem.count !== quantity) {
      const newQuantity = (warrantyLineItem?.count || 0) - quantity;
      Promise.all([
        updateItem(String(warrantyLineItem.lineItemId), newQuantity),
        updateItem(String(lineItem.lineItemId), quantity, '', { warrantyPlanSku: '' }),
      ]);
    }
    closingNikonCareSideEffects();
  }, [warrantyLineItem, quantity, lineItem.lineItemId]);

  return (
    <NikonCareStateManagementContext.Provider
      value={{
        warrantyLineItem,
        isLoading,
        mode,
        isNikonCareActive,
        onNikonCareToggleValue: handleNikonCareToggleValue,
        onAddToCart: handleAddToCart,
        onCloseNikonCare: handleCloseNikonCare,
        switchMode,
        dropdown: {
          onSelectPlanCategory: handleSelectPlanCategory,
          selectedPlanCategory,
          onSelectPlan: handleSelectPlan,
          selectedPlan,
        },
        productLineItemId: lineItem.lineItemId || '',
      }}
    >
      {children}
    </NikonCareStateManagementContext.Provider>
  );
};

export const useNikonCareStateManagement = () => {
  const stateManagementContext = React.useContext(NikonCareStateManagementContext);

  if (!stateManagementContext) {
    throw new Error('You need to be wrapped by NikonCareStateManagementProvider to use useNikonCareStateManagement');
  }

  return stateManagementContext;
};
