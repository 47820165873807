import { Money } from '@commercetools/frontend-domain-types/product';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { TNikonBundleType, TNikonVariant } from 'types/TNikonProduct';

export interface GetProductPricesWithSoftBundles {
  priceWithBundles: Money;
  discountPriceWithBundles: Money;
  discountAmountWithBundles: Money;
}

export const areArraysEqual = (arr1: string[] = [], arr2: string[] = []): boolean => {
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.length === sortedArr2.length && sortedArr1.every((elem, index) => elem === sortedArr2[index]);
};

export const areObjectsEquals = (
  obj1: Record<string, string | string[] | undefined>,
  obj2: Record<string, string | string[] | undefined>,
) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  return keys1.every((key) => {
    const val1 = obj1[key];
    const val2 = obj2[key];
    return Array.isArray(val1) && Array.isArray(val2) ? areArraysEqual(val1, val2) : val1 === val2;
  });
};

export const getVariantsSkus = (softBundle: TNikonVariant[]): string[] => softBundle.map(({ sku }) => sku);

export const getProductPricesWithSoftBundles = (
  variant: TNikonVariant,
  softBundleVariants: TNikonBundleType[] = [],
): GetProductPricesWithSoftBundles => {
  const bundlesPrices = softBundleVariants.map((v) => v?.basePrice);
  const bundleDiscountPrices = softBundleVariants.map((v) => v.discountedPrice);

  const variantPrice = variant.price?.custom?.fields.basePrice || variant?.price;
  const variantDiscount = variant?.discountedPrice || variant?.price;

  const priceWithBundles: Money | undefined = bundlesPrices.reduce((acc: Money, price: Money) => {
    return CurrencyHelpers.addCurrency(acc, price);
  }, variantPrice);

  const discountPriceWithBundles: Money | undefined = bundleDiscountPrices.reduce((acc: Money, price: Money) => {
    return CurrencyHelpers.addCurrency(acc, price);
  }, variantDiscount || variantPrice);

  const discountAmountWithBundles: Money | undefined =
    priceWithBundles &&
    discountPriceWithBundles &&
    CurrencyHelpers.subtractCurrency(priceWithBundles, discountPriceWithBundles);

  return {
    priceWithBundles: priceWithBundles || { centAmount: 0 },
    discountPriceWithBundles: discountPriceWithBundles || { centAmount: 0 },
    discountAmountWithBundles: discountAmountWithBundles || { centAmount: 0 },
  };
};
