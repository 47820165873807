import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import styles from './AdditionalInfoModal.module.scss';
import { useFormat } from 'helpers/hooks/useFormat';

interface Props {
  show: boolean;
  onClose: () => void;
}

const TestIdPrefix = 'nikon-care__additional-info-modal';

const AdditionalInfoModal = ({ show, onClose }: Props) => {
  const { formatMessage: formaNikonCareMessage } = useFormat({ name: 'nikon-care' });

  return (
    <Modal
      className="!p-0"
      dialogClassName={classNames(styles.modal_dialog, '!my-0 !ml-auto !mr-0')}
      contentClassName="!rounded-none"
      show={show}
      onHide={onClose}
      data-testid={`${TestIdPrefix}__container`}
    >
      <Modal.Header
        closeButton
        className={classNames(styles.modal_header, '!items-start')}
        data-testid={`${TestIdPrefix}__header`}
      >
        <img data-testid="nikon-care__logo" src="/icons/nikon-care.svg" loading="lazy" className="w-[270px] pt-25" />
      </Modal.Header>
      <Modal.Body className="pb-0 pt-3" data-testid={`${TestIdPrefix}__body`}>
        <p>
          <strong>
            {formaNikonCareMessage({
              id: 'additional-info-modal.description',
              defaultMessage: 'Enjoy your Nikon gear for years to come, worry-free.',
            })}
          </strong>
        </p>
        <h2 className="pt-1">
          {formaNikonCareMessage({
            id: 'additional-info-modal.title',
            defaultMessage: 'Plan Highlights',
          })}
        </h2>
        <ul className="pl-[15px]">
          <li className="list-disc">
            <p>
              {formaNikonCareMessage({
                id: 'additional-info-modal.bullet-1',
                defaultMessage: 'Up to 3 years coverage available for eligible Nikon products.',
              })}
            </p>
          </li>
          <li className="list-disc">
            <p>
              {formaNikonCareMessage({
                id: 'additional-info-modal.bullet-2',
                defaultMessage: 'Complimentary replacement of any product determined to be unrepairable.',
              })}
            </p>
          </li>
          <li className="list-disc">
            <p>
              {formaNikonCareMessage({
                id: 'additional-info-modal.bullet-3',
                defaultMessage: 'Rapid repair processing with complimentary shipping.',
              })}
            </p>
          </li>
          <li className="list-disc">
            <p>
              {formaNikonCareMessage({
                id: 'additional-info-modal.bullet-4',
                defaultMessage:
                  'Nikon Care+ also includes Accidental Damage from Handling (ADH) coverage for even more peace of mind.',
              })}
            </p>
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer className="justify-content-start mb-[140px]" data-testid={`${TestIdPrefix}__footer`}>
        <a
          href={formaNikonCareMessage({
            id: 'additional-info-modal.learn-more.link',
            defaultMessage: '#',
          })}
          className="text-decoration-none"
        >
          <strong>
            {formaNikonCareMessage({
              id: 'additional-info-modal.learn-more.label',
              defaultMessage: 'LEARN MORE',
            })}{' '}
            {'>'}
          </strong>
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export default AdditionalInfoModal;
