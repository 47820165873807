import React from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { LineItem } from 'types/cart';
import styles from './NikonCareSummary.module.scss';
import { useNikonCareStateManagement } from '../../context';
import { useNikonCarePreviewMode } from '../../hooks/useNikonCarePreviewMode';
import { useNikonCareWarrantyPlansFormatted } from '../../hooks/useNikonCareWarrantyPlansFormatted';
import { Logo } from '../logo';

const NikonCareSummary = ({
  variant,
  quantity = 0,
  warrantyPlanVariant,
  containerClassName,
  logoImgClassName,
  order,
}: {
  variant: LineItem['variant'] | any;
  quantity: number;
  warrantyPlanVariant?: LineItem['variant'];
  containerClassName?: string;
  logoImgClassName?: string;
  order?: any;
}) => {
  const router = useRouter();
  const warrantyPlans = useNikonCareWarrantyPlansFormatted({ variant, quantity });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { planCategorySelected, planSelected } = useNikonCarePreviewMode({
    warrantyPlans,
    quantity,
    warrantyPlanVariant,
  });
  // selectedPlan is the SKU of the plan selected
  // we need to use it to get the plan details from the order object
  const {
    dropdown: { selectedPlan },
  } = useNikonCareStateManagement();

  // if we have an order object, the user has purchased the plan
  // so, on the thank-you page, we need to use that data
  // otherwise, we are in the modal overlay and need to use the current plan selected by the user...
  const purchasePlan = !!order ? order.lineItems.find((item: any) => item.variant.sku === selectedPlan) : planSelected;

  // check to see if we are in the overlay modal then fallback to the order object
  // the overlay modal will occur first and no order object will be available,
  // so we need to check for that first...
  const name = purchasePlan?.label || purchasePlan?.name;
  const price =
    purchasePlan?.priceSubtotal ||
    CurrencyHelpers.formatForCurrency(
      purchasePlan?.price.centAmount,
      router.locale,
      purchasePlan?.price.curencyCode,
      purchasePlan?.price.fractionDigits,
    );

  if (!warrantyPlans?.length || !purchasePlan) return null;
  return (
    <div className={classNames(styles.summary, containerClassName)}>
      <div className="d-flex flex-col justify-center gap-1">
        <Logo
          {...(!!logoImgClassName && { img: { className: logoImgClassName } })}
          description={{ className: 'mb-0 text-sm', isHidden: true }}
        />
      </div>
      <div>
        <p className="leading-0 mb-0 text-sm">
          <strong>{planCategorySelected.label}</strong>
        </p>
        <p className="mb-0 text-sm">
          <strong>{name}</strong>
        </p>
      </div>
      <div className="d-flex items-center justify-between gap-1 md:ml-auto md:flex-col md:items-end md:justify-start">
        <p className="text-l mb-0">
          <strong>{price}</strong>
        </p>
        <p className="mb-0 text-xs md:-mt-3">
          {formatCartMessage({ id: 'product.count', defaultMessage: 'QTY:' })} {quantity}
        </p>
      </div>
    </div>
  );
};

export default NikonCareSummary;
