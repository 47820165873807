import { Cart, LineItem } from 'types/cart';
import { DEFAULT_CART_ERROR_MESSAGES } from './constant';

export const isLineItemWarrantyPlan = (lineItem: LineItem) => {
  return (
    Object.hasOwn(lineItem?.variant?.attributes, 'accidentalDamageCoverage') &&
    Object.hasOwn(lineItem?.variant?.attributes, 'planTerm')
  );
};

export const filterBundleProductLineItem = (lineItems: LineItem[]): Array<LineItem[]> => {
  const bundlesLineItems: Record<string, LineItem[]> = {};

  lineItems.forEach((lineItem) => {
    if (
      lineItem?.variant?.attributes?.bundleWithList?.length &&
      lineItem?.variant?.sku &&
      !isLineItemWarrantyPlan(lineItem)
    ) {
      bundlesLineItems[lineItem.variant.sku] = [lineItem];
    }
  });

  lineItems.forEach((lineItem) => {
    const itemSku = lineItem?.variant?.sku;
    if (!itemSku || bundlesLineItems[itemSku]) return;

    const parentBundleSku = lineItem?.custom?.fields?.parentBundleSku;
    const parentItem = parentBundleSku ? bundlesLineItems[parentBundleSku]?.[0] : null;
    const parentItemBundleWithList = parentItem?.variant?.attributes?.bundleWithList;

    if (parentItemBundleWithList?.includes(itemSku)) {
      bundlesLineItems[parentBundleSku] = [...bundlesLineItems[parentBundleSku], lineItem];
    }
  });

  return Object.values(bundlesLineItems)?.filter((bundle) => bundle?.length > 1);
};

export const filterWithoutBundleProductLineItem = (lineItems: LineItem[], bundleData: LineItem[] | null) => {
  if (bundleData && Boolean(bundleData?.length)) {
    const bundleSku = bundleData.map((lineItem) => Number(lineItem?.variant?.sku));
    const withoutBundleWithList = lineItems.filter(
      (lineItem) => !isLineItemWarrantyPlan(lineItem) && !bundleSku.includes(Number(lineItem?.variant?.sku)),
    );

    if (Boolean(withoutBundleWithList.length)) {
      return withoutBundleWithList;
    }
    return null;
  }
  return lineItems.filter((lineItem) => !isLineItemWarrantyPlan(lineItem));
};

export const bundleFinancialInFo = (lineItems: LineItem[] | null, cartData: Cart) => {
  const currencyCode = cartData.sum?.currencyCode;
  const fractionDigits = cartData.sum?.fractionDigits;

  if (!lineItems?.length)
    return {
      subtotal: { centAmount: 0, currencyCode: currencyCode, fractionDigits: 2 },
      discount: { centAmount: 0, currencyCode: currencyCode, fractionDigits: 2 },
      total: { centAmount: 0, currencyCode: currencyCode, fractionDigits: 2 },
    };

  const subTotalAmount = lineItems.reduce(
    (acc, curr) => acc + (curr.price?.centAmount || 0) * (curr.count as number),
    0,
  );
  const totalAmount = lineItems.reduce(
    (acc, curr) => acc + (curr.totalPrice?.centAmount || 0) * (curr.count as number),
    0,
  );

  const discountedAmount = lineItems.reduce(
    (acc, curr) => acc + ((curr.price?.centAmount || 0) * (curr.count as number) - (curr.totalPrice?.centAmount || 0)),
    0,
  );

  return {
    subtotal: {
      centAmount: subTotalAmount,
      currencyCode,
      fractionDigits,
    },
    discount: {
      centAmount: discountedAmount,
      currencyCode,
      fractionDigits,
    },
    total: {
      centAmount: totalAmount,
      currencyCode,
      fractionDigits,
    },
  };
};

export const filterDiscountMessage = (data: any = []) => {
  if (Boolean(data.length)) {
    return data
      .map((message: any) => {
        if (
          message?.detail?.discountCodeState &&
          message.detail.discountCodeState !== 'MatchesCart' &&
          message.detail.discountCodeState !== 'MaxApplicationReached'
        ) {
          if (message.detail.discountCodeState !== 'NotValid') {
            return DEFAULT_CART_ERROR_MESSAGES.NotValid;
          }
          return DEFAULT_CART_ERROR_MESSAGES.NotActive;
        }
      })
      .filter(Boolean);
  }
  return [];
};

export const makeMessagesForNoInventory = (data: any = []) => {
  if (Boolean(data?.skus?.length)) {
    return data.skus.map(
      ({ lineItemName, skuName }: any) =>
        `${skuName && typeof skuName === 'string' ? skuName : lineItemName} ${DEFAULT_CART_ERROR_MESSAGES.noInventory}`,
    );
  }
  return [];
};

export const makeMessagesForAdjustedQty = (data: any = []) => {
  if (Boolean(data?.length)) {
    return data?.map(
      (item: { skuName: string; qtyAdjusted: number }) =>
        `${DEFAULT_CART_ERROR_MESSAGES.qtyAdjustedPart1} ${item?.skuName || 'item'} ${
          DEFAULT_CART_ERROR_MESSAGES.qtyAdjustedPart2
        } ${item?.qtyAdjusted || 'item'} ${DEFAULT_CART_ERROR_MESSAGES.qtyAdjustedPart3}`,
    );
  }
  return [];
};
