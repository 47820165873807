import React from 'react';
import { useRouter } from 'next/router';
import { getProductPricesWithSoftBundles } from 'components/commercetools-ui/organisms/product/ProductDetails/components/ProductInformation/utils';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { TNikonProduct } from 'types/TNikonProduct';
import { useCart, useProduct } from 'frontastic';
import { useNikonCareResponse } from './useNikonCare.type';

export const hasAccidentalDamageCoverage = (plan: TNikonProduct) =>
  !!plan?.variants?.[0]?.attributes?.accidentalDamageCoverage;

export const useNikonCareWarrantyPlansFormatted = ({ variant, quantity }: { variant: any; quantity: number }) => {
  const { getWarrantyPlans } = useProduct();
  const [warrantyPlans, setWarrantyPlans] = React.useState<TNikonProduct[]>([]);
  const { formatMessage } = useFormat({ name: 'nikon-care' });
  const { getCartWarrantyPlans } = useCart();
  const router = useRouter();

  React.useEffect(() => {
    setWarrantyPlans([]);

    // Only product discounts are available on the Procuct object
    // That is fine if we are on the PDP, but if we are on the cart,
    // we need the Cart object to get cart discounts and bundle+save discounts
    const isCart = router.asPath.includes('cart') || router.asPath.includes('checkout');

    if (isCart) {
      // if we're on the cart or checkout page,
      // we need to fetch the current Cart and base the warranties
      // on the price minus the valid cart discounts
      getCartWarrantyPlans(variant?.sku).then((plans) => {
        setWarrantyPlans(() => plans);
      });
    } else {
      // if we're on the PDP, we can base the warranties on the valid Product discount
      getWarrantyPlans(variant?.sku).then((plans) => {
        setWarrantyPlans(() => plans);
      });
    }
  }, [variant?.sku]);

  const formatWarrantyPlan = React.useCallback(
    (plan: TNikonProduct) => {
      const variant = plan?.variants?.[0];
      // It doesn't exist bundles in warranty plans so it sends an empty array.
      const { priceWithBundles } = getProductPricesWithSoftBundles(variant, []);

      return {
        value: variant?.sku,
        label: formatMessage({
          id: 'dropdown.option.label',
          defaultMessage: 'Nikon Care Plus',
          values: { quantity: variant?.attributes?.planTerm?.key || '0' },
        }),
        price: CurrencyHelpers.formatForCurrency(priceWithBundles, router.locale),
        priceSubtotal: CurrencyHelpers.formatForCurrency(
          { ...priceWithBundles, centAmount: (priceWithBundles?.centAmount || 0) * quantity },
          router.locale,
        ),
      };
    },
    [quantity, variant],
  );

  const formattedWarrantyPlans: useNikonCareResponse['warrantyPlans'] = React.useMemo(() => {
    if (!warrantyPlans?.length) return [];

    const nikonCarePlans = warrantyPlans
      ?.filter((plan) => !hasAccidentalDamageCoverage(plan))
      ?.map((plan) => formatWarrantyPlan(plan));
    const nikonCarePlusPlans = warrantyPlans
      ?.filter((plan) => hasAccidentalDamageCoverage(plan))
      ?.map((plan) => formatWarrantyPlan(plan));

    return [
      {
        value: 'nikon.care.plus',
        label: formatMessage({ id: 'nikon.care.plus', defaultMessage: 'Nikon Care + (with Accident Protection)' }),
        options: nikonCarePlusPlans,
      },
      {
        value: 'nikon.care',
        label: formatMessage({ id: 'nikon.care', defaultMessage: 'Nikon Care' }),
        options: nikonCarePlans,
      },
    ].filter(({ options }) => options?.length);
  }, [warrantyPlans, quantity]);

  return formattedWarrantyPlans;
};
