import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
// import { LineItem } from 'types/cart';
import styles from './NikonCareSummaryOutlined.module.scss';
import { useNikonCarePreviewMode } from '../../hooks/useNikonCarePreviewMode';
import { useNikonCareWarrantyPlansFormatted } from '../../hooks/useNikonCareWarrantyPlansFormatted';
import { Logo } from '../logo';

const NikonCareSummaryOutlined = ({ variant, quantity = 0 }: { variant: any; quantity: number }) => {
  const warrantyPlans = useNikonCareWarrantyPlansFormatted({ variant, quantity });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { planCategorySelected, planSelected } = useNikonCarePreviewMode({ warrantyPlans, quantity });

  if (!warrantyPlans?.length || !planSelected) return null;
  return (
    <div className={styles.summary}>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-col gap-1">
          <Logo
            img={{ className: 'w-[120px] min-w-[120px] md:w-[150px] mt-1' }}
            description={{ className: 'mb-0 text-sm', isHidden: true }}
          />
        </div>
        <div>
          <p className="mb-0 text-xl">
            <strong>{planSelected.priceSubtotal}</strong>
          </p>
          <p className="-mt-3 mb-0 text-end text-sm">
            {formatCartMessage({ id: 'product.count', defaultMessage: 'QTY:' })} {quantity}
          </p>
        </div>
      </div>
      <div>
        <p className="mb-0 text-sm leading-4">
          <strong>{planCategorySelected.label}</strong>
        </p>
        <p className="mb-0 text-sm">
          <strong>{planSelected.label}</strong>
        </p>
      </div>
    </div>
  );
};

export default NikonCareSummaryOutlined;
